import React, { useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import SuscripcionConductualGPT from './landingData/SuscripcionConductualGPT';
import './Modal.css';

const Componente1 = () => {
    const videoRef = useRef(null);
    const videoElement = videoRef.current;
  useEffect(() => {
    
    if (videoRef.current) {
      const playPromise = videoRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log("Reproducción automática exitosa");
          })
          .catch(error => {
            console.log("Reproducción automática fallida", error);
            
          });
      }
    }
  }, []);
    return (
      <div className="componente1">
           <video ref={videoRef} width="420" height="340" controls loop autoPlay muted playsInline>
           <source src="images/contableGPT2.mp4" type="video/mp4" />
      </video>
      </div>
    );
  };





const Modal = ({ isOpen, onClose }) => {
    const handleClose = () => {
      onClose();
    };
  
    if (!isOpen) {
      return null;
    }

  return (
    <>
    <div className="modal-wrapper">
      <div className="modal-content"><button className="close-button" onClick={handleClose}>
            <AiOutlineClose />
          </button>
        <div className="modal-header">
        ¿Cuál es el costo de la suscripción?
        </div>
        <div className="modal-body">
          <Componente1 />
          <SuscripcionConductualGPT/>
         
        </div>
      </div>
    </div>
</>
  );
};

export default Modal;