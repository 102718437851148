// parseLegalText.js

function parseLegalText(text) {
    const lines = text.split('\n').map(line => line.trim()).filter(line => line !== '');
  
    let result = {
      titulo: '',
      ley: '',
      articulos: []
    };
  
    let currentArticle = null;
    let inArticulosSection = false;
  
    for (let line of lines) {
      if (line.startsWith('Título:')) {
        result.titulo = line.replace('Título:', '').trim();
      } else if (line.startsWith('Ley:')) {
        result.ley = line.replace('Ley:', '').trim();
      } else if (line.startsWith('Artículos:')) {
        inArticulosSection = true;
      } else if (inArticulosSection) {
        if (line.startsWith('- Artículo')) {
          if (currentArticle) {
            result.articulos.push(currentArticle);
          }
          currentArticle = {
            numero: line.replace('- ', '').trim(),
            incisos: []
          };
        } else if (line.startsWith('  - Inciso')) {
          if (currentArticle) {
            currentArticle.incisos.push(line.replace('  - ', '').trim());
          }
        } else if (line.startsWith('-')) {
          if (currentArticle) {
            result.articulos.push(currentArticle);
          }
          currentArticle = {
            numero: line.replace('- ', '').trim(),
            incisos: []
          };
        }
      }
    }
  
    if (currentArticle) {
      result.articulos.push(currentArticle);
    }
  
    return result;
  }
  
  export default parseLegalText;
  
  
  