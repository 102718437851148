import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useNavigate } from "react-router-dom";
import Trash from '../icons/tarsh';

function DeleteButton({ index,postId, commentId, callback , refetch}) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const mutation = commentId ? DELETE_COMMENT_MUTATION : DELETE_POST_MUTATION;

  const [deletePostOrMutation, { loading, error }] = useMutation(mutation, {
    onError: (error) => {
      console.error('Mutation error:', error.message);
      // Handle error display or logging here
    },
    update(proxy) {
      setConfirmOpen(false);
      if (!commentId) {
        const data = { ...proxy.readQuery({ query: FETCH_POSTS_QUERY }) };
        data.getPosts = data.getPosts.filter((p) => p.id !== postId);
        proxy.writeQuery({ query: FETCH_POSTS_QUERY, data });
      }
      if (callback) callback();
    },
    variables: {
      postId,
      commentId
    }
    
  });

  const handleConfirm = () => {
    deletePostOrMutation()
      .then(() => {
        navigate(`/new/chat`, { state: { refetchNeeded: true } });
      })
      .catch((error) => {
        console.error('Mutation execution error:', error.message);
        // Handle error display or logging here
      });
      
      ///navigate(`/new/chat`);
     /// refetch();
  };

  return (
    <>
      <span
        style={{ cursor: 'pointer', color: '#333', padding: '15px 15px' }}
        onClick={() => setConfirmOpen(true)}
        onMouseEnter={() => setShowTooltip(index)}
        onMouseLeave={() => setShowTooltip(null)}
      >
        <Trash/> {/* Icono X para eliminar */}
      
  
      </span>
      
      {confirmOpen && (
        <div style={{ marginTop: 10 }}>
          <p>¿Estás seguro de que quieres eliminar este chat?</p>
          <button 
  onClick={() => setConfirmOpen(false)} 
  style={{
    backgroundColor: 'transparent',
    color: 'black',
    border: 'none',
    fontSize: '16px',
    padding: '10px',
    cursor: 'pointer'
  }}
>
  Cancelar
</button>

<button 
  onClick={handleConfirm} 
  disabled={loading}
  style={{
    backgroundColor: 'transparent',
    color: 'black',
    border: 'none',
    fontSize: '16px',
    padding: '10px',
    cursor: loading ? 'not-allowed' : 'pointer',
    opacity: loading ? 0.5 : 1
  }}
>
  Confirmar
</button>
          {error && <p style={{ color: 'red' }}>Error al eliminar.</p>}
        </div>
      )}
    </>
  );
}

const DELETE_POST_MUTATION = gql`
  mutation deletePost($postId: ID!) {
    deletePost(postId: $postId)
  }
`;

const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($postId: ID!, $commentId: ID!) {
    deleteComment(postId: $postId, commentId: $commentId) {
      id
      comments {
        id
        username
        createdAt
        body
        respuesta
      }
      commentCount
    }
  }
`;

export const FETCH_POSTS_QUERY = gql`
  {
    getPosts {
      id
      body
      estado
      createdAt
      username
      likeCount
      likes {
        username
      }
      commentCount
      comments {
        id
        username
        createdAt
        body
        respuesta
      }
    }
  }
`;

export default DeleteButton;


