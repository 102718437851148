import React from 'react';

const TextoOrganizado = ({ texto }) => {
  const parseText = (text) => {
    const lines = text.split('\n').filter(line => line.trim() !== '');
    const content = [];
    let currentListItems = [];
    let listType = null;

    const addListIfNotEmpty = () => {
      if (currentListItems.length > 0) {
        if (listType === 'ol') {
          content.push(<ol key={content.length}>{currentListItems}</ol>);
        } else if (listType === 'ul') {
          content.push(<ul key={content.length}>{currentListItems}</ul>);
        }
        currentListItems = [];
        listType = null;
      }
    };

    lines.forEach((line, index) => {
      if (line.startsWith('###')) {
        addListIfNotEmpty();
        content.push(<h2 key={index}>{line.slice(3).trim()}</h2>);
      } else if (index === 0) {
        content.push(
          <p key={index}>
            <img src='/images/logo-black2.png' className='chat-img-bot' style={{ marginRight: '10px' }} />
            {line}
          </p>
        );
      } else if (line.match(/^\d+\./)) {
        if (listType !== 'ol') {
          addListIfNotEmpty();
          listType = 'ol';
        }
        const items = line.split(/\d+\.\s/).filter(item => item.trim() !== '');
        items.forEach((item, i) => {
          const formattedItem = formatText(item).replace(/^- /, ''); // Remove leading '-' if present
          currentListItems.push(<li key={`${index}-${i}`} dangerouslySetInnerHTML={{ __html: formattedItem }}></li>);
        });
      } else if (line.match(/^-\s/)) {
        if (listType !== 'ul') {
          addListIfNotEmpty();
          listType = 'ul';
        }
        const item = line.slice(2).trim();
        currentListItems.push(<li key={index} dangerouslySetInnerHTML={{ __html: formatText(item) }}></li>);
      } else {
        addListIfNotEmpty();
        content.push(<p key={index} dangerouslySetInnerHTML={{ __html: formatText(line) }}></p>);
      }
    });

    addListIfNotEmpty(); // Add any remaining list items

    return content;
  };

  const formatText = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  return (
    <>
      {parseText(texto)}
    </>
  );
};

export default TextoOrganizado;





