import React, { useState, useRef, useEffect } from 'react';
//import { ApolloClient, InMemoryCache } from '@apollo/client';
import '../App.css'; // Estilos CSS para el chat
import { 
    FaArrowDown, 
    FaDownload, 
    FaCheckCircle,
    FaRedo, 
    FaCaretDown, 
    FaArrowRight, 
    FaSignInAlt, 
    FaSignOutAlt,
    } from 'react-icons/fa'; // Importa los iconos de menú, cerrar, flecha hacia abajo, descarga, copiar y regenerar
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
//import Landing from './landing';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';
import FilteredPosts from './historialTrained';
///import { Link } from 'react-router-dom';

///// Modulos propios
import { generatePdf } from './modulos-propios/generatePDF';
import { StreamingText } from './modulos-propios/streamingText';
//import useAutoScroll from './modulos-propios/useAutoScroll'; // Importa el módulo useAutoScroll
import useScrollToBottom from './modulos-propios/useScrolledToBottom'; // Importa el módulo useScrollToBottom
import useClickOutside from './modulos-propios/useClickOutside'; // Importa el módulo useClickOutside
///import Form from './PostForm';
import Nuevo from './icons/nuevo';
///import Copy from './icons/copy';
import AbrirSide from './icons/arrowLeft';
import CerrarSide from './icons/arrowRigth';
import client from '../apolloClient';
//import { addRoute, navigate } from './modulos-propios/myRouter';
import { useLocation  } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Puntitos from './deleteAndLikes/Spinner';


const Chat = () => {
  const [showSidebar, setShowSidebar] = useState(true); // Estado para controlar la visibilidad del sidebar
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); // Utiliza el módulo useClickOutside
  //const [messages, setMessages] = useState([]);
  const [currentChat, setCurrentChat] = useState([]);
  //const [isNew, setIsNew] = useState(false); // Variable de estado isNew agregada

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
/////STEP FORM
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('');
  const [info, setInfo] = useState('');
  const [message, setMessage] = useState('');
  const [isLoadingPost, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef); // Utiliza el módulo useScrollToBottom
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const [submitComment] = useMutation(SUBMIT_COMMENT_MUTATION);
  const [submitPostrain] = useMutation(SUBMIT_POSTRAIN_MUTATION);

  const useradmin = process.env.REACT_APP_ADMIN || process.env.REACT_APP_ADMIN2 || process.env.REACT_APP_ADMIN3

  

  const handleTitleSubmit = (event) => {
    event.preventDefault('Por favor ingresa un título.');
    if (title.trim() === '') {
      setMessage('Por favor ingresa un título.');
    } else {
      setMessage('');
      setStep(2);
    }
  };

  const handleInfoSubmit = (event) => {
    event.preventDefault();
    if (info.trim() === '') {
      setMessage('Por favor ingresa la información denominada cuerpo.');
    } else {
      setInfo('');
      setIsLoading(true); // Muestra el spinner
      setMessage('');
      handleNewPostrain();
    }
  };
  
  //const { loading, error, data, refetch } = useQuery(FETCH_POSTS_QUERY);
  const username = user.sub.replace(/[^a-zA-Z0-9]/g, '');
  const { loading, error, data, refetch} = useQuery(FETCH_POSTS_QUERY_USER_TAINED, {
    variables: { username }
  });
  const location = useLocation();

  useEffect(() => {
    if (location.state?.refetchNeeded) {
      refetch();
    }
  }, [location.state, refetch]);

  //// Cerrar sidebar si la pantalla en menos a desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSidebar(false);
      }
    };
    handleResize(); // Verificar tamaño al cargar la página

    window.addEventListener('resize', handleResize); // Verificar tamaño al cambiar el tamaño de la pantalla

    return () => window.removeEventListener('resize', handleResize); // Limpiar el event listener
  }, []);

  //// funcion que filtra los nuevos mensajes para aplicar el streaming
  const [botResponses, setBotResponses] = useState([]);
  useEffect(() => {
    // Filtrar las respuestas del bot que aún no se han mostrado
    const newBotResponses = currentChat.filter(message => message.sender === 'bot' && !botResponses.includes(message.text));
    // Actualizar el estado de las respuestas del bot
    setBotResponses(prevBotResponses => [
      ...prevBotResponses,
      ...newBotResponses.map(message => message.text)
    ]);
  }, [currentChat, messageInput]);
  

  /////// scrolling a traves del Boton
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }
  };

  //// Maneja la visivilidad del Boton Menu al lado de user avatar (unico del sitio dropdown)
  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  

  const handleRegenerate = () => {
    // Lógica para regenerar la respuesta del chatbot
    // Por ejemplo, podrías volver a enviar el último mensaje del usuario y actualizar la respuesta
  };

  //// BARRA DE LA DERECHA
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar); // Cambia el estado de visibilidad del sidebar
  };

  ////// FUNCIONAMIENTO DEL INPUT POR MEDIO DEL ENTER
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Evita que el formulario se envíe automáticamente
      sendMessage(e);
    }
  };



  
  const [createdPost, setCreatedPost] = useState(null);
 // const [data, setData] = useState(null);

  
  const createPostMutation = () => {
    
    return client.mutate({
      mutation: SUBMIT_POSTRAIN_MUTATION,
      variables: { titulo: title , cuerpo:info, username:user.sub },
      onCompleted: (data) => {
        console.log('Mutación completada: ', data);
        //console.log('ID: ', data.createPost.id);
        // Suponiendo que el id del post se encuentra en la propiedad 'id' de la respuesta
        // Redirigir al route "/chat/:postId"
       
      },
      onError: (error) => {
        console.error('Error en la mutación: ', error);
      }
    });
  }

 
  
  const callCreatePost = async (refetch) => {
    try {
      const response = await createPostMutation();
      console.log("Llamada a createPostMutation exitosa:", response.data.createPostTrain.id);
      const postId = response.data.createPostTrain.id;
      await refetch(); // Refetch posts to update the list
      setCreatedPost(true); // Marca que el post ha sido creado
      navigate(`/singletrained/${postId}`);
    } catch (error) {
      console.error("Error al llamar a createPostMutation:", error);
    } finally {
      setIsLoading(false); // Oculta el spinner una vez que termina el proceso
    }
  };

  const handleNewChat = () => {
    navigate(`/new/chat`);
    refetch();
   
  };

  const handleNewPostrain = () => {
    // Lógica para iniciar un nuevo chat ---AQUI AGREGAR LOGICA DE [Crear el post y pasar el id post en el calback]
    callCreatePost(refetch);
   
  };
 


 // Función para enviar un mensaje
 const sendMessage = () => {
  if (messageInput.trim() !== '') {
   
    const callCreatePost = async (refetch) => {
      try {
        const response = await createPostMutation();
        console.log("Llamada a createPostMutation exitosa:", response.data.createPost.id);
        const postId = response.data.createPost.id;
        
        submitComment({ 
          variables: { postId: postId, body: messageInput },
        });
        
        await refetch(); // Refetch posts to update the list
        navigate(`/chat/${postId}`);
       
       
      } catch (error) {
        console.error("Error al llamar a createPostMutation:", error);
      }
    }
    callCreatePost(refetch);
    
    setMessageInput(''); // Limpiar el input después de enviar el mensaje
  }
};


  return (
    <div className="chat-container">
    {/* Enviar */}
   
    {/* Mensajes chat */}
    {!createdPost? (
    <div className="spinner-container">
    <div className="contexto-container" >
    {!isLoadingPost && <h2>Hola estas en el entrenador de contableGPT</h2>}
    {!isLoadingPost && <p>Por favor, Ingresa la informacion contable para generar la base de conocimiento...</p>}
      {isLoadingPost && <><h3>Creando contexto vectorial </h3><Puntitos /></>}
    </div>
    </div>
) : (
    isAuthenticated && (
     <div className="main2">
           <div className="messages" ref={bodymessagesEndRef}> 
             {currentChat.map((message, index) => (
               <div key={index} className={`message ${message.sender}`} ref={messagesEndRef}>
                 {message.sender === 'bot' ? (
                   <StreamingText text={message.text} setIsStreaming={setIsStreaming}/>
                 ) : (
                  <><img src={user.picture} alt={user.name} className='chat-img-user'/> 
                  <span>{message.text}</span>
                  <div className="message-options">
                  <button className='pregunta-options-button' onClick={() => handleRegenerate(message.text)}><FaRedo /></button>
                  </div>
                  </> 
                 )}
                 {message.sender === 'bot' && (
                   <div className="message-options">
                       <button className='respuesta-options-button' onClick={() => generatePdf(message.text)}><FaDownload /></button>
                   </div>
                 )}
               </div>
             ))}
             
             </div>
         </div>
       )
     )}
     {/* BARRA PARTE SUPERIOR */}
     <div className="floating-menu">
       {/* LOGO --- ISOLOGO */}
       <img src='/images/LOGO-contableGPT-01.svg' className="floating-menu-logo-2" />
     {/* CONTENIDO LOGIN--LOGOUT Y Menu */}
     <div className="sidebar-buttons">
       {!isAuthenticated && (
         <button onClick={() => loginWithRedirect()}>
           <FaSignInAlt />
           <span>Ingresar</span>
         </button>
       )}
     </div>
     {isAuthenticated && (
       <div className="profile-container">
       <img src={user.picture} alt={user.name} />
       <div id="dropdown" className="dropdown">
          <button onClick={handleDropdownToggle}>
           <FaCaretDown />
          </button>   
           {showDropdown && (
            <div className="dropdown-menu">
            <button>
              <a className='button-link' href="#" onClick={() => navigate(`/new/chat`)}>Ir al Chat</a>
            </button>
          
            <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
              <span>Salir</span>
              <FaSignOutAlt />
            </button>
          </div>
          )}
       </div>
     </div>   
     )}
  </div>
  {/* Sidebar */}
  {isAuthenticated && (
      <div className={`sidebar ${showSidebar ? 'open' : 'close'}`}>
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        {showSidebar ? <AbrirSide /> : <CerrarSide />}
      </div>
   {/* Contenido del sidebar */}
  <div className="sidebar-content">
  
      <div className="button-container">
         <button onClick={handleNewChat} >
       <Nuevo size={10}/>
      <p>Ir al chat</p>
         </button>
       
       </div>
       <p>Historial...</p>
       <FilteredPosts refetch={refetch} user={user.sub} />
   </div>
{/* Botones en la parte inferior del sidebar */}

</div>
)}
{/* Input chat */}
  <div className="mainTrained">
        
     {isAuthenticated && !isLoadingPost &&(
         
         <div className="input-container">
         {step === 1 && (
        <form onSubmit={handleTitleSubmit}>
          <label>
            Ingresar título de referencia
            <input
              type="text"
              placeholder="Ingresa título representativo de la base de conocimiento contable..."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              
            />
          </label>
          <button type="submit" ><FaArrowRight size={15} /></button>
          {message && <p style={{ color: 'red' }}>{message}</p>}
        </form>
      )}

      {step === 2 && (
        <form onSubmit={handleInfoSubmit}>
          <label>
            Ingresar información que denominamos "Cuerpo"
            <input
              type="text"
              placeholder="Ingresa cuerpo para generar la base de conocimiento contable..."
              value={info}
              onChange={(e) => setInfo(e.target.value)}
              
            />
          </label>
          
          <button type="submit" ><FaArrowRight size={15} /></button>
          {message && <p style={{ color: 'red' }}>{message}</p>}
        </form>
      )}
       
     </div>
      
       )}
       <div className="disclaimer-base-container">
       <p >Contable-GPT ™ 2024 | by Contablegpt SRL</p>
       </div>
    
    </div>
{/* Scrolling Button */}
{isAuthenticated && (
   <>
   {bodymessagesEndRef.current && isScrolledToBottom == false && (
     <div className="scroll-to-bottom" onClick={scrollToBottom}>
         <FaArrowDown />
     </div>
   )}
   </>
  )}
       <div className="disclaimer-base-container-terminos">
           <a className='button-link' href="#" onClick={() => setIsModalOpen(true)}>Términos de uso.</a>
           <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
       </div>
       
</div>
);
};





const FETCH_POSTS_QUERY_USER_TAINED = gql`
  query getPostsTrainByUsername($username: String!) {
    getPostsTrainByUsername(username: $username) {
      id
      titulo
      createdAt
      username
    }
  }
`;

const FETCH_POST_QUERY = gql`
  query($postId: ID!) {
    getPost(postId: $postId) {
      id
      body
      estado
      createdAt
      username
      likeCount
      likes {
        username
      }
      commentCount
      comments {
        id
        username
        createdAt
        body
        respuesta
      }
    }
  }
`;

const SUBMIT_COMMENT_MUTATION = gql`
  mutation($postId: String!, $body: String!) {
    createComment(postId: $postId, body: $body) {
      id
      comments {
        id
        body
        respuesta
        createdAt
        username
      }
      commentCount
    }
  } 
`;


const SUBMIT_POSTRAIN_MUTATION = gql`
  mutation($titulo: String!, $cuerpo: String!, $username: String!) {
    createPostTrain(titulo: $titulo, cuerpo: $cuerpo, username: $username) {
        id
        titulo
        resumen
        cuerpo
        tacs
        contexto{
          user
          assistant
        }
    }
  } 
`;


const CREATE_POST_MUTATION = gql`
  mutation createPost($body: String!, $username: String!) {
    createPost(body: $body, username: $username) {
      id
      body
      estado
      createdAt
      username
      likes {
        id
        username
        createdAt
      }
      likeCount
      comments {
        id
        body
        respuesta
        username
        createdAt
      }
      commentCount
    }
  }
`;




export default Chat;
