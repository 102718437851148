import React, { useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import SuscripcionConductualGPT from './landingData/SuscripcionConductualGPT';
import './Modal.css';



const Modal = ({ isOpen, onClose }) => {
    const handleClose = () => {
      onClose();
    };
  
    if (!isOpen) {
      return null;
    }

  return (
    <>
    <div className="modal-wrapper">
      <div className="modal-content"><button className="close-button" onClick={handleClose}>
            <AiOutlineClose />
          </button>
        <div className="modal-header">
        ¿Cuanto cuesta la suscripción?
        </div>
        <div className="modal-body">
          <SuscripcionConductualGPT/>
         
        </div>
      </div>
    </div>
</>
  );
};

export default Modal;