import React, { useState, useRef } from 'react';
import '../App.css'; 
import {FaSignInAlt} from 'react-icons/fa'; 
import { useAuth0 } from "@auth0/auth0-react";
import Landing from './landing';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';



const Chat_landiong = () => {
 
  const [showDropdown, setShowDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect } = useAuth0();
 


  return (
    <div className="chat-container">
      {/* Landing */ isLoading}
      {isLoading ? (
         <div className="spinner-container">
            <div className="spinner">
               <img src='/images/logoNuevo.png' alt="loading" />
            </div>
         </div>
            ) : (!isAuthenticated && (<Landing />))}
      
      <div className="floating-menu">
      
        <img src='/images/LOGO-contableGPT-01.svg' className="floating-menu-logo-2" />
        
        <div className="sidebar-buttons">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>
              <FaSignInAlt />
              <span>Ingresar</span>
            </button>
          )}
        </div>
      </div>
      <div className="disclaimer-base-container-terminos-landing">
      <p >Contable-GPT ™ 2024 | by Contablegpt SRL</p>
      <a className='button-link' href="#" onClick={() => setIsModalOpen(true)}>Términos de uso.</a>
      </div>
      <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
    </div>
  )
};


export default Chat_landiong;
