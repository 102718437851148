import React, { useState, useEffect } from "react";

// Lista de 50 preguntas que los contadores podrían hacerle a ContableGPT, con preguntas relacionadas con el régimen aduanero
const textOptions = [
  "¿Cómo se calcula el IVA en Argentina?",
  "¿Cuáles son los límites de facturación para el Monotributo?",
  "¿Cómo se determina el Impuesto a las Ganancias para una empresa?",
  "¿Cuáles son los mecanismos de retención de IVA y Ganancias?",
  "¿Qué es el Sinceramiento Fiscal y cómo afecta a los contribuyentes?",
  "¿Cómo se calculan los aportes previsionales para autónomos?",
  "¿Qué operaciones están alcanzadas por el Impuesto PAIS?",
  "¿Cómo se aplican las multas e intereses por declaraciones impositivas fuera de término?",
  "¿Qué bienes están gravados por el Impuesto sobre los Bienes Personales?",
  "¿Cuáles son los beneficios de la moratoria impositiva?",
  "¿Cómo declarar ingresos provenientes del exterior?",
  "¿Qué es el Régimen de Factura Electrónica y quiénes están obligados a usarlo?",
  "¿Cómo se calculan los anticipos del Impuesto a las Ganancias?",
  "¿Cuál es la diferencia entre retenciones y percepciones?",
  "¿Cómo regularizar una deuda con AFIP?",
  "¿Cuáles son las categorías del Monotributo y cómo se recategoriza?",
  "¿Qué gastos son deducibles en el Impuesto a las Ganancias?",
  "¿Cómo se computan los créditos fiscales de IVA?",
  "¿Qué hacer ante una inspección de AFIP?",
  "¿Cómo se determina la residencia fiscal de un contribuyente?",
  "¿Qué diferencias hay entre autónomos y empleados en relación de dependencia en términos impositivos?",
  "¿Cómo se tributan los alquileres de inmuebles en Argentina?",
  "¿Qué es el Régimen de Información de Participaciones Societarias y cómo se presenta?",
  "¿Qué implicancias tiene la exención en el Impuesto a las Ganancias para jubilados?",
  "¿Cómo se determina la categoría en el régimen de Monotributo para un nuevo profesional?",
  "¿Cuáles son las exenciones en el Impuesto sobre los Bienes Personales?",
  "¿Cómo afecta la doble imposición en el comercio internacional?",
  "¿Cómo se tramita una devolución de IVA en exportaciones?",
  "¿Qué documentación es necesaria para una auditoría contable?",
  "¿Cómo se aplican las retenciones a proveedores del exterior?",
  "¿Qué pasa si no se presenta la declaración jurada de Ganancias a tiempo?",
  "¿Qué son las percepciones en el Impuesto al Valor Agregado?",
  "¿Cómo se deducen los gastos de representación en Ganancias?",
  "¿Qué pasa si se supera el límite de facturación en el Monotributo?",
  "¿Cómo calcular el ajuste por inflación impositivo?",
  "¿Qué es el Régimen de Sinceramiento Fiscal y quiénes pueden acceder?",
  "¿Cómo declarar bienes adquiridos en el exterior?",
  "¿Cuáles son las implicancias fiscales de las sociedades offshore?",
  "¿Qué requisitos deben cumplir los comprobantes electrónicos?",
  "¿Cómo se solicita la exención del Impuesto a las Ganancias para ONG?",
  "¿Cuáles son las deducciones personales permitidas en Ganancias?",
  "¿Cómo se calculan los aportes para empleados en relación de dependencia?",
  "¿Cuáles son los requisitos para despachos de importación?",
  "¿Cómo se gestionan las declaraciones de importación en la aduana?",
  "¿Qué es el régimen de destinaciones suspensivas en el comercio exterior?",
  "¿Cómo se calcula el derecho de importación sobre productos extranjeros?",
  "¿Qué documentos son necesarios para la exportación de mercaderías?",
  "¿Qué es el despacho simplificado de mercaderías?",
  "¿Cómo se tributan las importaciones temporales?",
  "¿Qué requisitos aduaneros se deben cumplir para importar bienes de capital?",
  "¿Cómo se realiza la clasificación arancelaria de productos en aduana?"
];

const StreamingText = ({ speed = 50, interval = 8000 }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [randomText, setRandomText] = useState("");
  const [previousText, setPreviousText] = useState(""); // Estado para almacenar el texto anterior
  const [showCursor, setShowCursor] = useState(true); // Estado para mostrar/ocultar el cursor

  // Función para seleccionar un texto aleatorio que no sea igual al anterior
  const getRandomText = () => {
    let selectedText;
    do {
      const randomIndex = Math.floor(Math.random() * textOptions.length);
      selectedText = textOptions[randomIndex];
    } while (selectedText === previousText); // Repite si el texto es igual al anterior
    return selectedText;
  };

  // Selecciona un texto aleatorio al montar el componente y cada vez que se cambia
  useEffect(() => {
    const startNewText = () => {
      const selectedText = getRandomText();
      setRandomText(selectedText);
      setDisplayedText(""); // Resetea el texto mostrado
      setCurrentIndex(0); // Reinicia el índice
      setPreviousText(selectedText); // Guarda el texto seleccionado como el anterior
      setShowCursor(true); // Muestra el cursor cuando empieza un nuevo texto
    };

    startNewText(); // Inicia el primer texto

    const intervalId = setInterval(() => {
      startNewText(); // Cambia el texto cada X segundos
    }, interval);

    return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonta
  }, [interval]);

  // Lógica para mostrar el texto progresivamente
  useEffect(() => {
    if (currentIndex < randomText.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + randomText[currentIndex]);
        setCurrentIndex(currentIndex + 1);
      }, speed);
      return () => clearTimeout(timeout);
    } else {
      setShowCursor(false); // Oculta el cursor cuando el texto termina de mostrarse
    }
  }, [currentIndex, randomText, speed]);

  return (
    <div style={styles.textContainer}>
      <p style={styles.text}>
        {displayedText}
        {showCursor && <span style={styles.cursor}></span>} {/* Muestra el cursor solo si showCursor es true */}
      </p>
    </div>
  );
};

const styles = {
  textContainer: {
    width: "100%",
    padding: "10px",
    borderRadius: "8px",
    background: "linear-gradient(90deg, black 0.20%, #ff4500 30%, #ed9960 69.8%)", // Degradado desde negro al 0.20%, #ff4500 al 30%, y el resto #ed9960
    WebkitBackgroundClip: "text", // Aplica el degradado al texto
    WebkitTextFillColor: "transparent", // Hace que solo el texto tenga el degradado
    fontFamily: "monospace",
    fontSize: "16px",
    minHeight: "50px",
    marginTop: "20px", // Añadido margen superior para separarlo de otros componentes
  },
  text: {
    margin: 0,
    whiteSpace: "pre-wrap",
    background: "none", // Remueve el fondo del texto, para que tome el degradado del contenedor
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  cursor: {
    display: "inline-block",
    width: "8px",
    height: "14px",
    backgroundColor: "#000000", // Color negro para el cursor
    animation: "blink 1s step-start infinite", // Animación de parpadeo
  },
};

export default StreamingText;

