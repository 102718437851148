import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import { 
    FaArrowDown, 
    FaDownload, 
    FaCheckCircle,
    FaRedo, 
    FaCaretDown, 
    FaArrowRight, 
    FaSignInAlt, 
    FaSignOutAlt,
    } from 'react-icons/fa'; 
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';
import FilteredPosts from './historialTemporal';
import { generatePdf } from './modulos-propios/generatePDF';
import { StreamingText } from './modulos-propios/streamingText';
import useScrollToBottom from './modulos-propios/useScrolledToBottom';
import useClickOutside from './modulos-propios/useClickOutside';
import Nuevo from './icons/nuevo';
import Copy from './icons/copy';
import AbrirSide from './icons/arrowLeft';
import CerrarSide from './icons/arrowRigth';
import client from '../apolloClient';
import { useLocation, useNavigate } from 'react-router-dom';
import SurveyComponent from './encuesta/SurveyComponent';
import HelpEncuesta from './helpEncuesta';
import InputComponent from './inputSinfoco/InputComponent';
import OneTimeLink from './links/linkBase';
import Puntitos from './deleteAndLikes/Spinner2';
const useradmin = process.env.REACT_APP_ADMIN || process.env.REACT_APP_ADMIN2 || process.env.REACT_APP_ADMIN3

const Chat = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); 
  const [isLoadingPost, setIsLoading] = useState(false);
  const [currentChat, setCurrentChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const [isModalHELP, setIsModalHELP] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  const [submitComment] = useMutation(SUBMIT_COMMENT_MUTATION);
  const username = user.sub.replace(/[^a-zA-Z0-9]/g, '');
  
  const { loading, error, data, refetch } = useQuery(FETCH_POSTS_QUERY_USER, {
    variables: { username }
  });
  const { data: userConductualData, loading: userConductualLoading, error: userConductualError, refetch: refetchUserConductual } = useQuery(FETCH_USERCONDUCTUAL_QUERY, {
    variables: { username },
    skip: !isAuthenticated
  });
  const location = useLocation();

  useEffect(() => {
    if (location.state?.refetchNeeded) {
      refetch();
    }
  }, [location.state, refetch]);

  useEffect(() => {
    if (isAuthenticated) {
      refetchUserConductual();
    }
  }, [isAuthenticated, refetchUserConductual]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSidebar(false);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [botResponses, setBotResponses] = useState([]);
  useEffect(() => {
    const newBotResponses = currentChat.filter(message => message.sender === 'bot' && !botResponses.includes(message.text));
    setBotResponses(prevBotResponses => [
      ...prevBotResponses,
      ...newBotResponses.map(message => message.text)
    ]);
  }, [currentChat, messageInput]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const [showInfo, setShowInfo] = useState(false);
  const [showCopyIcon, setShowCopyIcon] = useState(true);

  const handleCopy = () => {
    const chatText = currentChat.map(message => `${message.sender}: ${message.text}`).join('\n');
    navigator.clipboard.writeText(chatText);
    setShowInfo(true);
    setShowCopyIcon(false);
    setTimeout(() => {
      setShowInfo(false);
      setShowCopyIcon(true);
    }, 1500);
  };

  const handleRegenerate = () => {
    // Lógica para regenerar la respuesta del chatbot
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const [createdPost, setCreatedPost] = useState(null);

  const createPostMutation = () => {
    return client.mutate({
      mutation: CREATE_POST_MUTATION,
      variables: { body: 'Nuevo chat', username: username },
      onCompleted: (data) => {
        console.log('Mutación completada: ', data);
      },
      onError: (error) => {
        console.error('Error en la mutación: ', error);
      }
    });
  }

  const callCreatePost = async (refetch) => {
    try {
      const response = await createPostMutation();
      console.log("Llamada a createPostMutation exitosa:", response.data.createPost.id);
      const postId = response.data.createPost.id;
      await refetch();
      navigate(`/chat/${postId}`);
    } catch (error) {
      console.error("Error al llamar a createPostMutation:", error);
    }
  }

  const handleNewChat = () => {
    callCreatePost(refetch);
  };



  const sendMessage = () => {
    if (messageInput.trim() !== '') {
      setIsLoading(true);
  
      const callCreatePost = async (refetch) => {
        try {
          // Crea un nuevo post (chat)
          const response = await createPostMutation();
          const postId = response.data.createPost.id;
  
          // Envía el comentario con el contenido de `messageInput`
          await submitComment({
            variables: { postId: postId, body: messageInput, username: username },
          });
  
          await refetch(); // Refresca los posts para actualizar la lista
  
          // Redirige a la nueva ruta del chat creado
          navigate(`/chat/${postId}`);
  
          // Añadir el mensaje al chat actual
          setCurrentChat([...currentChat, { text: messageInput, sender: 'user' }]);
  
          setIsLoading(false);
        } catch (error) {
          console.error("Error al llamar a createPostMutation:", error);
        }
      };
  
      callCreatePost(refetch);
      setMessageInput(''); // Limpiar el input después de enviar el mensaje
    }
  };
  

  const userConductual = userConductualData?.getUserconductual;

  return (
    <div className="chat-container">
      {!createdPost  ? (
        <div className="spinner-container">
         <div className="responsive-container">
             {!isLoadingPost &&<h1>Hola, ¿en qué puedo ayudarte?</h1>}
             {!isLoadingPost &&<h5>{`${user.name}`}</h5>}
             {isLoadingPost && <Puntitos />}
          </div>
        </div>
      ) : (
        isAuthenticated  && (
          <div className="main2">
            <div className="messages" ref={bodymessagesEndRef}>
              {currentChat.map((message, index) => (
                <div key={index} className={`message ${message.sender}`} ref={messagesEndRef}>
                  {message.sender === 'bot' ? (
                    <StreamingText text={message.text} setIsStreaming={setIsStreaming} />
                  ) : (
                    <>
                      <img src={user.picture} alt={user.name} className='chat-img-user' />
                      <span>{message.text}</span>
                     
                    </>
                  )}
                  {message.sender === 'bot' && (
                    <div className="message-options">
                      <button className='respuesta-options-button' onClick={() => generatePdf(message.text)}><FaDownload /></button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )
      )}
      <div className="floating-menu">
        <img src='/images/LOGO-contableGPT-01.svg' className="floating-menu-logo-2" />
        <div className="sidebar-buttons">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>
              <FaSignInAlt />
              <span>Ingresar</span>
            </button>
          )}
        </div>
        {isAuthenticated && (
          <div className="profile-container">
            <img src={user.picture} alt={user.name} />
            <div id="dropdown" className="dropdown">
              <button onClick={handleDropdownToggle}>
                <FaCaretDown />
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
                 
                   <button>
                     <a className='button-link' href="#" onClick={() => navigate(`/trained`)}>Entrenar</a>
                   </button>
                  
                  <button>
                    <a className='button-link' href="#" onClick={() => setSuscripcionesOpen(true, setShowSidebar(false))}>Suscripcion</a>
                  </button>
                  <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    <span>Salir</span>
                    <FaSignOutAlt />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {isAuthenticated && (
        <div className={`sidebar ${showSidebar ? 'open' : 'close'}`}>
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {showSidebar ? <AbrirSide /> : <CerrarSide />}
          </div>
          <div className="sidebar-content">
            <div className="button-container">
            
              <button onClick={handleNewChat}>
                <Nuevo size={10} />
                <p>Nuevo chat</p>
              </button>
              <button onClick={handleCopy} style={{ paddingRight: '110px' }}>
                {showCopyIcon ? <Copy size={15} /> : <FaCheckCircle style={{ color: 'green' }} />}
                <p>{showCopyIcon ? 'Copiar' : 'Copiado'}</p>
              </button>
            </div>
            <p>Historial</p>
            <FilteredPosts refetch={refetch} user={user.sub} />
          </div>
        </div>
      )}
      <div className="main">
        
      {isAuthenticated && (
          <InputComponent messageInput={messageInput} setMessageInput={setMessageInput} handleKeyDown={handleKeyDown} sendMessage={sendMessage} isStreaming={isStreaming} />
      )}
          
        <div className="disclaimer-base-container">
        <p >Contable-GPT ™ 2024 | by Contablegpt SRL</p>
        </div>
      </div>
      {isAuthenticated && (
        <>
          {bodymessagesEndRef.current && isScrolledToBottom === false && (
            <div className="scroll-to-bottom" onClick={scrollToBottom}>
              <FaArrowDown />
            </div>
          )}
        </>
      )}
      <div className="disclaimer-base-container-terminos">
        <a className='button-link' href="#" onClick={() => setIsModalOpen(true)}>Términos de uso.</a>
        <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
        <HelpEncuesta isOpen={isModalHELP} onClose={() => setIsModalHELP(false)} />
      </div>
    </div>
  );
};

const FETCH_POSTS_QUERY_USER = gql`
  query getPostsByUsername($username: String!) {
    getPostsByUsername(username: $username) {
      id
      body
      estado
      createdAt
      username
      likeCount
      likes {
        username
      }
      commentCount
      comments {
        id
        body
        respuesta
        createdAt
        username
      }
    }
  }
`;

const FETCH_USERCONDUCTUAL_QUERY = gql`
  query($username: String!) {
    getUserconductual(username: $username) {
      id
      username
      disc
      eneagrama
      bigfive
    }
  }
`;

const SUBMIT_COMMENT_MUTATION = gql`
  mutation($postId: String!, $body: String!, $username: String!) {
    createComment(postId: $postId, body: $body, username: $username) {
      id
      comments {
        id
        body
        respuesta
        createdAt
        username
      }
      commentCount
    }
  } 
`;

const CREATE_POST_MUTATION = gql`
  mutation createPost($body: String!, $username: String!) {
    createPost(body: $body, username: $username) {
      id
      body
      estado
      createdAt
      username
      likes {
        id
        username
        createdAt
      }
      likeCount
      comments {
        id
        body
        respuesta
        username
        createdAt
      }
      commentCount
    }
  }
`;

export default Chat;
