import React from 'react';

const DescripcionConductualGPT = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
        <h2 style={{ textAlign: 'center' }}>Presentamos Contable-GPT: Tu Asistente Especializado para Profesionales Contadores Matriculados</h2>
        <li>¡Bienvenido a Contable-GPT, tu socio tecnológico diseñado exclusivamente para contadores matriculados! En un entorno donde la precisión, la normativa y la eficiencia son fundamentales, Contable-GPT se convierte en tu aliado estratégico, brindándote información avanzada y soporte en tiempo real para optimizar tu práctica profesional.</li>
    
        <h3>¿Qué es Contable-GPT?</h3>
        <li>Contable-GPT es un sofisticado sistema de inteligencia artificial, creado para apoyar a contadores matriculados en la gestión de sus tareas diarias, desde la elaboración de balances hasta la planificación fiscal. Este asistente está diseñado para integrarse en tu rutina profesional, ofreciéndote respuestas y soluciones basadas en las mejores prácticas contables y la normativa vigente.</li>
    
        <h3>¿De qué manera puede ayudarme Contable-GPT?</h3>
        <ul>
            <li><strong>Soporte Contable Especializado 24/7:</strong> Contable-GPT está disponible en cualquier momento para ofrecerte orientación técnica y normativa. Ya sea que necesites un análisis sobre los últimos cambios fiscales, ayuda con la preparación de estados financieros, o simplemente una segunda opinión, Contable-GPT está aquí para apoyarte.</li>
            <li><strong>Actualización en Normativas y Legislación:</strong> Mantente al día con las últimas actualizaciones normativas y cambios en la legislación contable. Contable-GPT te proporciona la información más reciente y relevante para asegurar que tus prácticas cumplen con los requisitos legales vigentes.</li>
            <li><strong>Optimización de Procesos Contables:</strong> Aumenta la eficiencia en tu trabajo diario con recomendaciones y herramientas que automatizan y mejoran procesos clave como la conciliación bancaria, la facturación, y la presentación de informes tributarios.</li>
            <li><strong>Soporte en Auditorías y Revisión de Cuentas:</strong> Prepara auditorías y revisiones de cuentas con confianza, utilizando el conocimiento y las sugerencias prácticas de Contable-GPT, que te ayudan a identificar áreas clave y mitigar riesgos potenciales.</li>
            <li><strong>Asesoramiento Personalizado:</strong> Cada práctica contable es única, y así es nuestra orientación. Contable-GPT adapta sus recomendaciones a las necesidades específicas de tu cartera de clientes, garantizando que recibas el apoyo más relevante y efectivo para tus casos particulares.</li>
        </ul>
    
        <h3>Importante: Contable-GPT como complemento a tu experticia</h3>
        <li>Es esencial recordar que Contable-GPT está diseñado para complementar, no para reemplazar, la experiencia y el juicio profesional de contadores matriculados. Este asistente proporciona información avanzada y soporte técnico, pero las decisiones críticas deben ser tomadas por el profesional en función de su conocimiento y la normativa aplicable. Contable-GPT es una herramienta poderosa para potenciar tu práctica, asegurando que tengas acceso a la información más actualizada y relevante.</li>
    
        <h3>Únete a Nuestra Comunidad de Profesionales Contables</h3>
        <li>Numerosos contadores matriculados ya están optimizando su práctica con la ayuda de Contable-GPT. Independientemente del tamaño de tu cartera de clientes, este asistente está aquí para apoyarte en cada paso del camino. ¡Comienza hoy y descubre el potencial de tener un aliado dedicado a fortalecer tu labor profesional! Recuerda que es una herramienta informativa y técnica, diseñada para complementar tu pericia.</li>
    
        <p style={{ textAlign: 'center' }}><strong>Con Contable-GPT, tu práctica contable está en buenas manos. ¡Vamos a empezar!</strong></p>
    </div>
    
    );
};

export default DescripcionConductualGPT;
