import React, { useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';

const InputComponent = ({ messageInput, setMessageInput, handleKeyDown, sendMessage, isStreaming }) => {
  
  useEffect(() => {
    const handleFocus = (event) => {
      if (window.innerWidth <= 768) {
        // No hacemos nada, dejamos que el input se enfoque manualmente si el usuario lo desea
      }
    };

    const inputElement = document.querySelector('.input-container input');
    inputElement.addEventListener('focus', handleFocus);

    return () => {
      inputElement.removeEventListener('focus', handleFocus);
    };
  }, []);

  return (
    <div className="input-container">
      <input
        type="text"
        placeholder="Hazme una pregunta contable..."
        value={messageInput}
        onChange={(e) => setMessageInput(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={isStreaming}
      />
      <button onClick={sendMessage}><FaArrowRight size={15} /></button>
    </div>
  );
};

export default InputComponent;

