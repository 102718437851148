import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import './ModalDetalle.css';
import parseLegalText from './modulos-propios/parseLegalText';

const Modal = ({ isOpen, onClose, titulo, resumen}) => {
  // Parseamos el resumen solo si tiene contenido relevante
  const resumenRender = resumen && resumen !== 'Aca va el resumen' ? parseLegalText(resumen) : null;

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="modal-overlay" onClick={handleClose}></div>
      <div className="modal-container">
        
            <button className="modal-header-detalles-close">
              <AiOutlineClose onClick={handleClose} />
            </button>
            <h3>{titulo}</h3>
            {resumenRender ? (
              <>
                <h4>Contenido</h4>
                <p><strong>Título:</strong> {resumenRender.titulo}</p>
                <p><strong>Ley:</strong> {resumenRender.ley}</p>
                <h4>Artículos</h4>
                <ul>
                  {resumenRender.articulos.map((articulo, index) => (
                    <li key={index}>
                      <strong>{articulo.numero}</strong>
                      {articulo.incisos && articulo.incisos.length > 0 && (
                        <ul>
                          {articulo.incisos.map((inciso, incisoIndex) => (
                            <li key={incisoIndex}>{inciso}</li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
                
              </>
            ) : (
              <>
               
              </>
            )}
          </div>
      
    </>
  );
};

export default Modal;


