import React from 'react';

const InstructivoChatAsistente = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
        <h2 style={{ textAlign: 'center' }}>¿Cómo se usa Contable-GPT?: Tu Asistente Contable Asistido con Inteligencia Artificial</h2>
    
        <h3>Introducción</h3>
        <li>Bienvenido a Contable-GPT, tu asistente contable asistido por inteligencia artificial. Este sistema está diseñado para proporcionarte apoyo técnico, orientación y estrategias prácticas para optimizar la gestión contable y financiera en tu práctica profesional. A continuación, te explicamos cómo funciona y cómo puedes aprovechar al máximo sus capacidades.</li>
    
        <h3>Paso 1: Ingreso al Sistema</h3>
        <ol>
            <li><strong>Acceso:</strong> Ingresa al sistema a través de la plataforma proporcionada (disponible a través de un sitio web y próximamente mediante una app móvil).</li>
            <li><strong>Registro (si es necesario):</strong> Si es tu primera vez usando el sistema, es necesario crear una cuenta proporcionando información básica como tu nombre, número de matrícula, correo electrónico y una contraseña segura.</li>
        </ol>
    
        <h3>Paso 2: Iniciar una Conversación</h3>
        <ol>
            <li><strong>Inicio del Chat:</strong> Una vez que hayas ingresado, encontrarás una interfaz de chat amigable y sencilla. Puedes comenzar la conversación planteando una consulta contable o financiera específica.</li>
            <li><strong>Saludo Inicial:</strong> El asistente se presentará y te dará la bienvenida. Puedes iniciar la conversación escribiendo sobre cualquier tema contable, desde dudas sobre normativa fiscal hasta estrategias de optimización de procesos contables.</li>
        </ol>
    
        <h3>Paso 3: Interactuar con el Asistente</h3>
        <ol>
            <li><strong>Diálogo Abierto:</strong> Escribe tus preguntas o describe las situaciones contables en las que necesites asistencia. El asistente está diseñado para entender y responder en lenguaje natural, proporcionando información técnica y relevante para contadores matriculados.</li>
            <li><strong>Respuestas Personalizadas:</strong> Contable-GPT utiliza inteligencia artificial para proporcionar respuestas precisas y útiles, adaptadas a la normativa vigente y a las necesidades específicas de tu práctica profesional.</li>
        </ol>
    
        <h3>Paso 4: Recibir Orientación y Consejos</h3>
        <ol>
            <li><strong>Asesoramiento Contable:</strong> El asistente puede ofrecerte orientación sobre la preparación de balances, liquidación de impuestos, conciliaciones bancarias y más, ayudándote a manejar tus tareas diarias con mayor eficiencia.</li>
            <li><strong>Optimización de Procesos Financieros:</strong> Recibirás sugerencias para mejorar la gestión de recursos financieros, como la implementación de automatizaciones y mejores prácticas contables.</li>
            <li><strong>Soporte en Decisiones Críticas:</strong> Contable-GPT está disponible para ofrecerte análisis y recomendaciones informadas que te ayuden a tomar decisiones financieras y contables clave.</li>
        </ol>
    
        <h3>Paso 5: Información y Recursos Adicionales</h3>
        <ol>
            <li><strong>Artículos y Tutoriales:</strong> El asistente puede recomendarte artículos, tutoriales y otros recursos educativos que te ayudarán a mantenerte actualizado en normativas y mejores prácticas contables.</li>
            <li><strong>Recordatorios y Seguimiento:</strong> Puedes establecer recordatorios para revisar tareas importantes y recibir seguimiento regular para monitorear el cumplimiento de tus obligaciones contables.</li>
        </ol>
    
        <h3>Paso 6: Configuración y Personalización</h3>
        <ol>
            <li><strong>Preferencias:</strong> Ajusta las preferencias del chat según tus necesidades, como el tipo de información que deseas recibir, la frecuencia de los recordatorios y las áreas en las que necesitas más apoyo. Estas configuraciones se adaptarán a medida que interactúas con la IA.</li>
            <li><strong>Feedback: (PRÓXIMAMENTE)</strong> Proporcionaremos retroalimentación sobre tus experiencias con el asistente para mejorar continuamente la calidad del servicio.</li>
        </ol>
    
        <h3>Importante: Contable-GPT como complemento a tu experiencia profesional</h3>
        <li>Es fundamental recordar que Contable-GPT está diseñado para complementar, no reemplazar, la experiencia y el juicio profesional de contadores matriculados. Este asistente proporciona soporte técnico y orientación avanzada, pero las decisiones críticas deben ser tomadas por el profesional, considerando la normativa vigente y su conocimiento especializado.</li>
    
        <h3>Conclusión</h3>
        <li>Contable-GPT está aquí para ayudarte a optimizar tu práctica contable y financiera, brindándote información y estrategias prácticas que te permiten ofrecer un mejor servicio a tus clientes. Aprovecha todas las funcionalidades del sistema y recuerda que siempre estamos aquí para acompañarte en tu labor profesional.</li>
    
        <p style={{ textAlign: 'center' }}><strong>¡Esperamos que esta descripción general te sea útil y te permita aprovechar al máximo las capacidades de Contable-GPT!</strong></p>
    </div>
    
    );
};

export default InstructivoChatAsistente;
