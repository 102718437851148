import React from 'react';
import '../Modal.css';

const SuscripcionConductualGPT = () => {
    return (
        <div className="suscripcion-container">
            <h2 style={{ textAlign: 'center' }}>¡Bienvenido a Contable-GPT!</h2>
            <li>Me alegra que estés aquí. Mi nombre es Contable-GPT y estoy aquí para ayudarte a optimizar tu práctica contable y mejorar la eficiencia de tu gestión financiera. Ofrezco un servicio de suscripción que se adapta a tus necesidades profesionales, y quiero contarte cómo puedes beneficiarte de nuestras opciones.</li>

            <h3>Contable-GPT Básico ($5/mes)</h3>
            <li>Por solo $5 al mes, el servicio básico de Contable-GPT te brinda acceso a una amplia gama de funcionalidades diseñadas para apoyarte en tu labor como contador matriculado. Esto incluye:</li>
            <ul>
                <li><strong>Asistencia Contable 24/7:</strong> Puedes consultarme en cualquier momento del día o de la noche. Estoy aquí para ofrecerte orientación técnica y apoyo en tus tareas contables diarias.</li>
                <li><strong>Estrategias de Optimización Financiera:</strong> Te proporcionaré técnicas y consejos efectivos para mejorar la eficiencia en la gestión de tus recursos financieros y contables.</li>
                <li><strong>Guía para la Automatización de Procesos:</strong> Te guiaré paso a paso en la implementación de soluciones automatizadas que te ayudarán a simplificar y acelerar tus tareas contables.</li>
                <li><strong>Consejos Personalizados:</strong> Mis respuestas y sugerencias estarán adaptadas a tus necesidades específicas, asegurando que recibas el apoyo más relevante para ti y tu práctica profesional.</li>
            </ul>

            <h3>Contable-GPT PLUS ($20/mes)</h3>
            <li>Estoy emocionado de presentarte Contable-GPT PLUS, una versión mejorada de mi servicio por solo $20 al mes. Con Contable-GPT PLUS, podrás acceder a características avanzadas que llevarán tu práctica contable al siguiente nivel:</li>
            <ul>
                <li><strong>Creación y Entrenamiento de Chats Contables Propios:</strong> Contable-GPT PLUS te permite crear y entrenar chats contables personalizados que puedes utilizar para atender virtualmente a tus clientes. Esta herramienta es ideal para mejorar la interacción con tus clientes y ofrecerles un servicio más eficiente y automatizado.</li>
                <li><strong>Consultas Especializadas:</strong> Si enfrentas desafíos complejos y necesitas asesoramiento especializado, Contable-GPT PLUS te permitirá hacer preguntas detalladas y recibir orientación específica sobre temas avanzados de contabilidad y finanzas.</li>
                <li><strong>Recursos Avanzados:</strong> Acceso a una biblioteca ampliada de artículos técnicos, guías, videos y herramientas educativas diseñadas para mejorar tu conocimiento y habilidades en la gestión contable.</li>
                <li><strong>Soporte Prioritario:</strong> Respuestas más rápidas y detalladas para tus consultas, asegurando que recibas la mejor asistencia en el menor tiempo posible.</li>
            </ul>

            <h3>Cómo Unirse a Contable-GPT</h3>
            <li>Suscribirse es fácil y rápido. Solo necesitas crear una cuenta proporcionándome tu nombre, matrícula profesional, correo electrónico y una contraseña segura. Una vez registrado y con la suscripción básica o PLUS activa, podrás acceder a todas las funcionalidades de Contable-GPT de inmediato y estarás listo para optimizar tu práctica contable.</li>

            <h3>Mantente Atento</h3>
            <li>Contable-GPT PLUS está disponible y listo para ayudarte a llevar tu práctica contable al siguiente nivel. Mientras tanto, si optas por el servicio básico, disfruta de los beneficios que este te ofrece y no dudes en aprovechar todo el apoyo y la orientación que puedo brindarte.</li>

            <h3 style={{ textAlign: 'center' }}>¡Gracias por ser parte de Contable-GPT!</h3>
            <li>Estoy aquí para ayudarte en cada paso de tu labor profesional. Si tienes alguna pregunta o necesitas asistencia, no dudes en iniciar una conversación conmigo. Juntos, podemos lograr una práctica contable más eficiente y efectiva.</li>

            <li style={{ textAlign: 'center' }}><strong>Este servicio de suscripción está diseñado para proporcionarte el apoyo que necesitas, cuando lo necesitas. ¡Hablemos y empecemos este camino juntos!</strong></li>
            <hr />

            <li><strong>Nota:</strong></li>
            <li>Este sistema de chat asistente contable está diseñado para proporcionar información y apoyo general en la gestión contable y financiera. Para decisiones críticas o situaciones complejas, consulta con un profesional certificado. Recuerda que el sistema y las respuestas NO reemplazan ni pretenden igualar bajo ninguna circunstancia el asesoramiento profesional de un contador certificado o asesor financiero.</li>

            <hr />
        </div>
    );
};

export default SuscripcionConductualGPT;
